/*------------------------------------------------------------------
[ Utility ]*/
.txt1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 1.5;
  color: #555555; }

.blue {
  color: #067bc2; }

.green {
  color: #22B573; }

.red {
  color: red; }

.f400 {
  font-weight: 400; }

.txt2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 1.5;
  color: #666666; }

.open-sans {
  font-family: "Open Sans", sans-serif; }

/*------------------------------------------------------------------
[ Login ]*/
.login-container {
  background: radial-gradient(rgba(0, 174, 239, 0.9) 0%, rgba(2, 86, 165, 0.9) 100%) no-repeat center center fixed, url("../../media/backgrounds/login-bg.jpg") no-repeat center center fixed;
  background-size: 100% 100%;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px; }

.login-wrapper {
  width: 360px;
  background: #FFFFFF;
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.tab-content {
  padding: 0 30px 30px 30px; }

.nav-tabs > li {
  width: 50%;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #067bc2 !important;
  background-color: #F0F3F5; }

.login-footer {
  width: 360px;
  padding: 20px;
  background-color: #F0F3F5;
  border-top: 1px solid #C8CED3; }
  .login-footer.orange {
    background-color: #FDFAD2; }

.login-logo {
  margin-bottom: 20px;
  width: 200px; }

.login-form {
  width: 300px; }

.form-control, .form-control:focus {
  border: none;
  border-bottom: 1px solid #555555;
  border-radius: 0;
  box-shadow: none; }

.login-form-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 25px;
  margin-top: 0; }

.input-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
  border: none; }

.input {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 30px 0 35px;
  border: none;
  border-bottom: 1px solid #555555; }
  .input.has-error {
    border-color: #ED462D; }

.input-error {
  color: #ED462D;
  text-align: left;
  font-size: 12px; }

/*------------------------------------------------------------------
[ Focus ]*/
.input-focus {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  color: #00aeef;
  border: none; }

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0 0 70px 25px;
    opacity: 0; } }

@keyframes anim-shadow {
  to {
    box-shadow: 0 0 70px 25px;
    opacity: 0; } }

.input-symbol {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  pointer-events: none;
  color: #666666;
  transition: all 0.4s; }
  .input-symbol.has-error {
    padding-bottom: 15px; }

.input:focus + .input-focus + .input-symbol {
  color: #00aeef;
  padding-left: 5px; }

/*------------------------------------------------------------------
[ Button ]*/
.button-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px; }

.login-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: #FFFFFF;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: #00aeef;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s; }

.login-button:hover {
  background: #00aeef;
  color: #FFFFFF; }

/*------------------------------------------------------------------
[ Responsive ]*/
@media (max-width: 992px) {
  .login-logo {
    width: 35%; }
  .login-form {
    width: 300px; } }

@media (max-width: 768px) {
  .login-logo {
    width: 200px; }
  .login-form {
    width: 300px; } }
