.main-container {
  max-width: 1400px;
  padding-top: 70px !important; }

.backdrop.show {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000;
  opacity: 0.5; }
