.custom-modal > .modal-content {
  margin-top: 150px;
  border-radius: 5px;
  border-width: 3px !important; }
  .custom-modal > .modal-content > .modal-header {
    border: none; }
    .custom-modal > .modal-content > .modal-header > .modal-title {
      margin-top: 30px;
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      text-align: center;
      font-weight: 500; }
    .custom-modal > .modal-content > .modal-header .modal-icon {
      width: 60px;
      height: 60px;
      text-align: center;
      font-size: 20px;
      color: #FFFFFF;
      border-radius: 50%;
      position: absolute;
      left: calc(50% - 30px);
      top: -30px;
      padding-top: 20px; }
  .custom-modal > .modal-content > .modal-body {
    border: none;
    font-family: 'Montserrat', sans-serif; }
    .custom-modal > .modal-content > .modal-body > .annotations {
      text-align: justify;
      padding-top: 20px; }
  .custom-modal > .modal-content > .modal-footer {
    border: none; }

.custom-modal.info > .modal-content {
  border-color: #17A2B8; }
  .custom-modal.info > .modal-content > .modal-header > .modal-icon {
    background-color: #17A2B8; }
  .custom-modal.info > .modal-content > .modal-footer {
    background-color: #17A2B8; }

.custom-modal.success > .modal-content {
  border-color: #22B573; }
  .custom-modal.success > .modal-content > .modal-header > .modal-icon {
    background-color: #22B573; }
  .custom-modal.success > .modal-content > .modal-footer {
    background-color: #22B573; }

.custom-modal.error > .modal-content {
  border-color: #ED462D; }
  .custom-modal.error > .modal-content > .modal-header > .modal-icon {
    background-color: #ED462D; }
  .custom-modal.error > .modal-content > .modal-footer {
    background-color: #ED462D; }

.custom-modal.warning > .modal-content {
  border-color: #FFB000; }
  .custom-modal.warning > .modal-content > .modal-header > .modal-icon {
    background-color: #FFB000; }
  .custom-modal.warning > .modal-content > .modal-footer {
    background-color: #FFB000; }

.custom-modal.question > .modal-content {
  border-color: #555555; }
  .custom-modal.question > .modal-content > .modal-header > .modal-icon {
    background-color: #FFFFFF;
    color: #555555;
    border: 3px solid #555555; }
  .custom-modal.question > .modal-content > .modal-footer {
    background-color: #555555; }
