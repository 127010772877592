.f400 {
  font-weight: 400; }

.f500 {
  font-weight: 500; }

.fs-16 {
  font-size: 16px; }

.fs-22 {
  font-size: 22px; }

.fs-32 {
  font-size: 32px; }

.blue {
  color: #067bc2; }

.open-sans {
  font-family: "Open Sans", sans-serif; }

.montserrat {
  font-family: 'Montserrat', sans-serif; }

.round {
  background-color: #C8CED3;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  box-shadow: 0 0 5px #002a3a;
  margin-bottom: 20px;
  margin-left: calc(50% - 25px); }
  .round.green {
    background-color: #1CD05D; }

.custom-button {
  background-color: #00aeef;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  min-width: 250px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 40px; }
  .custom-button.gray {
    background-color: #C8CED3; }

.custom-button-sm {
  background-color: #00aeef;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  min-width: 80px;
  margin-top: 20px;
  margin-bottom: 1px;
  height: 40px; }
  .custom-button-sm.gray {
    background-color: #C8CED3; }

.custom-button-green {
  background-color: #1CD05D;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  min-width: 250px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 40px; }
  .custom-button-green.gray {
    background-color: #C8CED3; }

.custom-button-danger {
  background-color: #ED462D;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  min-width: 250px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 40px; }
  .custom-button-danger.gray {
    background-color: #C8CED3; }

.custom-button-danger-sm {
  background-color: #ED462D;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  min-width: 80px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 40px; }
  .custom-button-danger-sm.gray {
    background-color: #C8CED3; }

.modal-button {
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  text-align: center; }
  .modal-button:hover {
    color: #FFFFFF; }
