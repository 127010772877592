nav {
  background: #FFFFFF;
  border-bottom: solid 1px #EDEDED;
  box-shadow: 0 0 20px #002a3a;
  transition: all .5s;
  height: 50px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  display: flex;
  justify-content: center; }

.toolbar-container {
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

#logo {
  padding: 5px;
  padding-left: 15px; }

#logo a {
  display: inline-block; }

#logo a:hover img {
  opacity: 0.8;
  cursor: pointer; }

#logo a h1, #logo a h2 {
  font-size: 10px;
  margin: 0;
  padding: 0; }

#logo a h2 {
  text-indent: -500px; }

.toogle {
  font-size: 24px;
  margin-right: 10px;
  margin-top: 10px; }

.toogle:hover {
  cursor: pointer;
  opacity: 0.8; }

.dropdown-menu {
  min-width: 185px; }
