.panel > .panel-heading {
  background-color: transparent; }

th {
  color: #067bc2; }

.button {
  margin: 0 auto;
  display: block; }

.red-button {
  font-size: 12px;
  border-radius: 100%;
  float: right;
  color: #dbdbdb;
  background-color: #f95451; }

.selectoptions {
  overflow: scroll;
  overflow-x: hidden;
  height: 150px;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #dbdbdb; }
  .selectoptions li {
    padding: 12px 10px; }
    .selectoptions li:nth-child(even) {
      background: #f8f8f8; }
    .selectoptions li button {
      font-size: 12px;
      border-radius: 100%;
      float: right;
      color: #dbdbdb;
      background-color: #1bd05d; }

.selectedoptions {
  overflow: scroll;
  overflow-x: hidden;
  background-color: #f2f2f2;
  height: 150px;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #dbdbdb;
  padding-top: 10px; }
  .selectedoptions p {
    font-family: Montserrat;
    font-size: 16px;
    color: #dbdbdb;
    text-align: center;
    margin-top: 35px; }
  .selectedoptions li {
    margin-left: 10px;
    margin-right: 10px;
    padding: 12px 20px; }
    .selectedoptions li:nth-child(even) {
      background: #f8f8f8; }
    .selectedoptions li button {
      font-size: 12px;
      border-radius: 100%;
      float: right;
      color: #dbdbdb;
      background-color: #f95451; }

.custom-button-photo {
  background-color: #00aeef;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  min-width: 150px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 40px; }
  .custom-button-photo.gray {
    background-color: #C8CED3; }

.DayPickerInput-OverlayWrapper {
  bottom: 0 !important;
  z-index: 1500;
  left: 0; }
  .DayPickerInput-OverlayWrapper .DayPickerInput-Overlay {
    border: 1px solid #00aeef; }

textarea {
  resize: none; }

.panel {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #067bc2;
  padding: 15px; }

.panelNoPadding {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #067bc2; }

.required {
  color: #ED462D; }

.label {
  font-family: "Open Sans", sans-serif;
  color: #555555;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px; }

.cv-input-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left; }

.cv-input {
  border: none;
  border-bottom: 1px solid #555555;
  padding-left: 5px;
  margin-bottom: 15px;
  padding-bottom: 5px; }
  .cv-input:focus {
    border: none;
    border-bottom: 1px solid #555555; }

.menu-cv {
  border-color: rgba(6, 123, 194, 0.6); }
  .menu-cv .menu-after {
    background-color: #f2faff; }
    .menu-cv .menu-after .title {
      font-family: Montserrat;
      font-size: 16px;
      font-style: Regular;
      color: #067bc2; }
    .menu-cv .menu-after .subtitle {
      display: none; }
  .menu-cv .menu-active {
    background-color: #067bc2; }
    .menu-cv .menu-active .title {
      font-family: Montserrat;
      font-size: 18px;
      font-weight: bold;
      color: #f2faff; }
    .menu-cv .menu-active .subtitle {
      font-family: Open Sans;
      font-size: 12px;
      color: #f2faff; }
  .menu-cv .menu-before {
    background-color: #ffffff; }
    .menu-cv .menu-before .title {
      font-family: Montserrat;
      font-size: 14px;
      color: #9d9d9d; }
    .menu-cv .menu-before .subtitle {
      display: none; }

.menu-title {
  font-family: Montserrat;
  font-size: 18px;
  color: #f2faff; }

.menu-subtitle {
  font-family: Open Sans;
  font-size: 12px;
  color: #f2faff; }

.menu-text {
  font-family: Montserrat;
  font-size: 14px;
  color: #9d9d9d; }

.menu-selected-background {
  background-color: #067bc2; }

.card-body {
  flex: 1 1 auto;
  padding: 2.25rem; }

.padding-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.padding-0-left {
  padding-left: 0 !important; }

.padding-0-right {
  padding-right: 0 !important; }

.top-margin-button-cv {
  margin-top: 20px;
  background-color: #BFBFBF;
  color: #F9F9F9; }

.btn-step {
  padding: 6px 25px; }

.btn.focus, .btn:focus, .btn:hover {
  color: #F9F9F9;
  text-decoration: none; }

a {
  text-decoration: none !important; }
