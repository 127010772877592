.hideNav {
  transition: all linear 0.7s;
  top: 50px;
  margin-right: -258px; }
  @media screen and (max-width: 480px) {
    .hideNav {
      margin-right: -300px;
      z-index: 1020; } }
  @media screen and (max-width: 768px) {
    .hideNav {
      z-index: 1020; } }

.showNav {
  transition: all linear 0.7s; }
  @media screen and (max-width: 768px) {
    .showNav {
      margin-left: -300px;
      z-index: 1020; } }
  @media screen and (max-width: 480px) {
    .showNav {
      margin-left: -300px;
      z-index: 1020; } }
  @media screen and (max-width: 320px) {
    .showNav {
      margin-left: -300px;
      z-index: 1020; } }

.remove {
  display: none; }

aside#slide_panel {
  background: #FFFFFF;
  color: rgba(255, 255, 255, 0.8);
  width: 210px;
  transition: all .5s;
  position: fixed;
  right: -160px;
  top: 50px;
  z-index: 1000;
  height: 100%;
  box-shadow: -4px 5px 30px 0px rgba(50, 50, 50, 0.69); }
  aside#slide_panel .fixed_wrap {
    width: 300px;
    position: fixed; }
  aside#slide_panel .menu-fixed {
    top: 60px;
    transition: all .5s;
    position: fixed;
    width: 100%;
    max-width: 320px;
    z-index: 1000; }
  aside#slide_panel .nav_aside h3 {
    color: #fff;
    font-family: Montserrat, serif;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 0;
    margin: 10px 10px 10px 40px; }
  aside#slide_panel .nav_aside ul.nav_l1 {
    padding-left: 0; }
    aside#slide_panel .nav_aside ul.nav_l1 li {
      display: flex;
      flex-direction: column; }
      aside#slide_panel .nav_aside ul.nav_l1 li.activo > a {
        color: #0066B4 !important; }
      aside#slide_panel .nav_aside ul.nav_l1 li a {
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        color: #A1A6AA;
        font-size: 2rem;
        font-family: Open Sans, sans-serif;
        padding: 1rem 1rem 1rem 1.6rem;
        width: 100%;
        text-decoration: none;
        transition: all .5s; }
        aside#slide_panel .nav_aside ul.nav_l1 li a:hover {
          background: #E4F6FD;
          color: #0066B4 !important; }
  aside#slide_panel .activo {
    background: #E4F6FD;
    border-left: solid 2px #0066B4; }
  aside#slide_panel .show_sub_nav {
    height: 300px !important;
    overflow: auto; }

.expand {
  width: 456px !important; }

.rotate {
  transform: rotate(180deg); }
